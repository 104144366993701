import React from 'react';
import IMAGES from "../images";
import Slider from "react-slick";
const Paddony = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div id="img">
                <div className="img">
                    <Slider {...settings} >
                        <div>
                            <img src={IMAGES.p1} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p2} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p3} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p4} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p5} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p6} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p7} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p9} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p10} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.p11} alt=""/>
                        </div>
                    </Slider>
                </div>
        </div>
    );
};

export default Paddony;