import React from 'react';
import {AiFillInstagram} from "react-icons/ai";
import {RiWhatsappFill} from "react-icons/ri";
import {BsTelegram} from "react-icons/bs";
const Footer = () => {
    return (
        <div id="footer">
            <div className="container">
                <div className="footer">
                   <h1>QVATRO</h1>
                   <div>
                       <a className="footer--instagram" href="https://www.instagram.com/qvatro_kg/"><AiFillInstagram/>Instagram</a>
                       <a className="footer--whatsapp" href="https://wa.me/message/6YSEF6DMZRFYE1"><RiWhatsappFill/>Whatsapp</a>
                       <a className="footer--telegram" href="https://t.me/ChegebaevMT"><BsTelegram/>Telegram</a>
                   </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;