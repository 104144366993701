import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import {RxHamburgerMenu} from "react-icons/rx";
import {LiaTimesSolid} from "react-icons/lia";
import IMAGES from "../../images";
const Header = () => {
    const [burger,setBurger] = useState(false)
    return (
        <div id="header">
            <div className="container">
                <div className="header">
                    <NavLink to={"/"}>
                    <div className="header--logo">
                        <img src={IMAGES.logo} alt=""/>
                        <h1>QVATRO</h1>
                    </div>
                    </NavLink>
                    <button style={{display: burger ? "none" : "block"}} className="header--burger" onClick={() => setBurger(true)}>
                        <p>
                            <RxHamburgerMenu/>
                        </p>
                    </button>
                    <button style={{display: burger ? "block" : "none"}} className="header--burger"  onClick={() => setBurger(false)}>
                        <p>
                            <LiaTimesSolid/>
                        </p>
                    </button>
                    <ul style={{
                        transition: "800ms",
                        transform: burger ? "" : "translateX(-800px)"
                    }}>
                        <li>
                            <NavLink to={"/"}>
                                Главная страница
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/paddony"}>
                                Душевые поддоны
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/vanny"}>
                            Ванны
                        </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/rakoviny"}>
                                Раковины
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/stoleshnitsy"}>
                                Столешницы
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;