import React from 'react';
import IMAGES from "../images";
import Slider from "react-slick";
const Vanny = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div id="img">
            <div className="container">
                <div className="img">
                    <Slider {...settings}>
                        <div>
                            <img src={IMAGES.v1} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v2} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v3} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v4} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v7} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v8} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v9} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v6} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.v5} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.r4} alt=""/>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Vanny;