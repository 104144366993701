import React, {useEffect} from 'react';
import IMAGES from "../../images";
import {SlLike} from "react-icons/sl";
import {GrSettingsOption} from "react-icons/gr";
import {GiThreeLeaves} from "react-icons/gi";
const Info = () => {
    return (
        <div id="info">
            <h1>Производство изделий из жидкого камня и стеклопластика</h1>
            <div className="container">
                <div className="info" >
                        <div className="info--blockSwiper">
                            <img  src={IMAGES.paddon} alt="dush"/>
                            <h3>Душевые поддоны</h3>
                        </div>
                        <div className="info--blockSwiper">
                            <img  src={IMAGES.vanna} alt="dush"/>
                            <h3>Сантехнические ванны</h3>
                        </div>
                        <div className="info--blockSwiper">
                            <img  src={IMAGES.rakovina} alt="dush"/>
                            <h3>Сантехнические раковины</h3>
                        </div>
                </div>
                <div className="aboutUs">
                    <h1>Почему выбирают нас?</h1>
                    <p className="aboutUs--title">Для изготовления нашей продукции, мы используем только качественные материалы. Поставщики литьевых смол: Германия,
                        США, Польша, Турция. Отлитые под заказ
                        ванны, поддоны для душа, раковины и столешницы
                        прослужат не один десяток лет. Цельнолитое изделие
                        без швов и склеек из литьевого мрамора в два раза
                        прочнее акрила и  натурального камня. Наша технология
                        менее доступная в связи с многосоставным сложным
                        технологическим процессом, но более качественная.
                        Литьевой мрамор признан  лучшим материалом данного
                        столетия для сантехнических и декоративных изделий.</p>

                    <div className="aboutUs--blocks">
                        <div className="aboutUs--blocks__blockIcon">
                            <a>
                                <GrSettingsOption/>
                            </a>
                            <p>Производственная мощность более 2000 изделий в месяц.</p>
                        </div>
                        <div className="aboutUs--blocks__blockIcon">
                            <a>
                                <SlLike/>
                            </a>
                            <p>Мы создаем сантехнику, которая заслужила только положительные отзывы потребителей.</p>
                        </div>
                        <div className="aboutUs--blocks__blockIcon">
                            <a>
                                <GiThreeLeaves/>
                            </a>
                            <p>Только экологически чистое сырье (гелькоут, смолы) импортного производства.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;