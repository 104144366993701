import React from 'react';
import IMAGES from "../images";
import Slider from "react-slick";
const Rakoviny = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div id="img">
            <div className="container">
                <div className="img">
                    <Slider {...settings}>
                        <div>
                            <img src={IMAGES.r6} alt=""/>
                        </div>
                        <div>
                            <img src={IMAGES.r5} alt=""/>
                        </div>
                        <div>
                           <img src={IMAGES.r7} alt=""/>
                        </div>
                        <div>
                           <img src={IMAGES.r8} alt=""/>
                        </div>
                        <div>
                           <img src={IMAGES.r9} alt=""/>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Rakoviny;