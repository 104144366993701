import Slider from "react-slick";
import IMAGES from "../../images";
import Aos from "aos"
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const ImCard = () => {
    const [img, setImg] = useState<boolean>(false)
    useEffect(() => {
        Aos.init({duration: 1000})
    },[])
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
    };
    return (
        <div id="card">
            <div className="container">
                <div className="card" data-aos="fade-up">
                    <h1>Душевые поддоны</h1>
              <Link to={"/paddony"}>
                  <Slider {...settings2} >
                      <div>
                          <img src={IMAGES.p1} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p2} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p3} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p4} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p5} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p6} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p7} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p9} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p10} alt=""/>
                      </div>
                      <div>
                          <img src={IMAGES.p11} alt=""/>
                      </div>
                  </Slider>
              </Link>
                </div>
                <div className="card" data-aos="fade-up">
                    <h1>Ванны</h1>
               <Link to={"/vanny"}>
                   <Slider {...settings2}>
                       <div>
                           <img src={IMAGES.v1} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v2} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v3} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v4} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v7} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v8} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v9} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v6} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.v5} alt=""/>
                       </div>
                       <div>
                           <img src={IMAGES.r4} alt=""/>
                       </div>
                   </Slider>
               </Link>
                </div>
                <div className="card" data-aos="fade-up">
                    <h1>Раковины</h1>
             <Link to={"/rakoviny"}>
                 <Slider {...settings2}>
                     <div>
                         <img src={IMAGES.r6} alt=""/>
                     </div>
                     <div>
                         <img src={IMAGES.r5} alt=""/>
                     </div>
                     <div>
                         <img src={IMAGES.r7} alt=""/>
                     </div>
                     <div>
                         <img src={IMAGES.r8} alt=""/>
                     </div>
                     <div>
                         <img src={IMAGES.r9} alt=""/>
                     </div>
                 </Slider>
             </Link>
                </div>
                <div className="card" data-aos="fade-up">
                    <h1>Столешницы</h1>
                 <Link to={"/stoleshnitsy"}>
                     <Slider {...settings2}>
                         <div>
                             <img src={IMAGES.s4} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.s6} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.s7} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.s10} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.s11} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.r1} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.r2} alt=""/>
                         </div>
                         <div>
                             <img src={IMAGES.r3} alt=""/>
                         </div>
                     </Slider>
                 </Link>
                </div>
            </div>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2924.409510261093!2d74.5067!3d42.8642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7dc91b3c881%3A0x9b6ac70cde27dd8f!2zVkc3NStKNzcsINCR0LjRiNC60LXQug!5e0!3m2!1sru!2skg!4v1699085260524!5m2!1sru!2skg"  height="450"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default ImCard;