import React from 'react';
import Hero from "../Hero";
import Info from "../Info";
import ImCard from "../ImCard";
import Footer from "../Footer";

const Home = () => {
    return (
        <div>
            <Hero/>
            <Info/>
            <ImCard/>
            <Footer/>
        </div>
    );
};

export default Home;