import React from 'react';
import './App.scss';
import Header from "./components/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import Rakoviny from "./pages/Rakoviny";
import Stolesh from "./pages/Stolesh";
import Vanny from "./pages/Vanny";
import Paddony from "./pages/Paddony";

function App() {
  return (
    <div className="App">
       <Header/>
        <Routes>
            <Route path={"/"} element={ <Home/> }/>
            <Route path={"/rakoviny"} element={ <Rakoviny/> }/>
            <Route path={"/stoleshnitsy"} element={ <Stolesh/> }/>
            <Route path={"/vanny"} element={ <Vanny/> }/>
            <Route path={"/paddony"} element={ <Paddony/> }/>
        </Routes>
    </div>
  );
}

export default App;
