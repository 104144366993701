import React from 'react';
import Slider from "react-slick";
import {BsArrowRight} from "react-icons/bs";


const Hero = () => {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <div id="hero">
                <div className="hero">
                    <Slider {...settings}>
                        <div className="hero--block1">
                            <h2>Производства душевых <br/> поддонов по индивидуальным <br/> размерам</h2>
                        </div>
                        <div className="hero--block2">
                            <h2>Производства столешниц с <br/> раковинами по <br/> индивидуальным размерам</h2>
                        </div>
                        <div className="hero--block3">
                            <h2>Производства сантехнических <br/>  по индивидуальным <br/> размерам</h2>
                        </div>
                    </Slider>
                </div>
        </div>
    );
};

export default Hero;