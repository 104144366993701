const IMAGES: any = {
    paddon: require("./paddondush.jfif"),
    rakovina: require("./rakovinaa.jfif"),
    vanna: require("./vana-ypsilon.jpg"),
    p1: require("./pad1.jfif"),
    p2: require("./pad2.jfif"),
    p3: require("./pad3.jfif"),
    p4: require("./pad4.jfif"),
    p5: require("./pad5.jfif"),
    p6: require("./pad6.jfif"),
    p7: require("./pad7.jfif"),
    p9: require("./pad9.jfif"),
    p10: require("./pad10.jfif"),
    p11: require("./pad11.jpg"),
    v1: require("./van1.jfif"),
    v2: require("./van2.jfif"),
    v3: require("./van3.jfif"),
    v4: require("./van4.jfif"),
    v5: require("./van5.jfif"),
    v6: require("./vanV1.jpg"),
    v7: require("./van6.jpg"),
    v8: require("./v8.jpg"),
    v9: require("./v9.jpg"),
    r1: require("./r1.jfif"),
    r2: require("./r2.jfif"),
    r3: require("./r3.jfif"),
    r4: require("./r4.jfif"),
    r5: require("./r5.jfif"),
    r6: require("./r6.jfif"),
    r7: require("./r7.jfif"),
    r8: require("./r8.jpg"),
    r9: require("./r9.jpg"),
    r10: require("./r10.jpg"),
    s4: require("./s4.jfif"),
    s5: require("./s5.jfif"),
    s6: require("./s6.jfif"),
    s7: require("./s7.jfif"),
    s8: require("./s8.jfif"),
    s9: require("./s9.jfif"),
    s10: require("./s10.jpg"),
    s11: require("./s11.jpg"),
    logo: require("./logo1.png")
}
export default IMAGES